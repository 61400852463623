@use "src/styles/utils/z-index";

.sticky {
  position: sticky;
  top: 0;
  z-index: z-index.$top;
}

.devmode-indicator {
  position: fixed;
  right: 3px;
  top: 2px;
  z-index: z-index.$top;
  margin-bottom: -0.5em; // Move bottom content closer
  background-color: #fcf0be;
  color: black;
  border-radius: 5px 5px 5px 5px;
  border: 0px solid #000000;
  box-shadow: 0px 0px 5px 1px rgba(255, 247, 194, 0.8);
  cursor: pointer;
}

.dev {
  padding: 1em;

  .toggles-container {
    display: flex;
    flex-wrap: wrap;
    gap: 0.25em;
  }

  .nin {
    cursor: pointer;
  }

  .buttons-container {
    display: flex;
  }

  .icon-settings-container {
    display: flex;
    flex-direction: column;

    * {
      margin: 0.25em;

      &:hover {
        cursor: pointer;
      }
    }
  }

  .data-panel {
    display: flex;
    position: absolute;
    flex-direction: column;
    top: 14em;
    left: 1em;
    background-color: blueviolet;
    opacity: 0.9;
    z-index: z-index.$top;

    .drag-handle {
      background-color: darkviolet;
      height: 1.5em;
      cursor: move;
    }

    pre {
      margin: 0 2px;
    }

    .clickable {
      margin: 1em 0.5em;
      cursor: pointer;
      border: 1px solid;
    }
  }

  .spinner-reload {
    display: flex;
    flex-direction: column;
  }

  .row {
    display: flex;
    justify-content: space-between;
    > * {
      flex: 1 1 0.5em;
    }
  }

  .bold {
    font-weight: 700;
  }

  .white-text {
    color: white !important;
  }

  .spinner-headline-wrapper {
    display: flex;
  }

  button {
    margin-left: 0.1em;

    &.enabled {
      background-color: #79b473;
      color: white;
    }
    &.disabled {
      background-color: #ed5041;
      color: white;
    }
  }

  .dropdown {
    background-color: white;
    height: 3em;
    margin: 0 2px 0;
    top: 6px;
  }

  .large-dropdown-panel {
    min-width: fit-content !important;
    max-height: none !important;
  }
}

app-user-settings-panel {
  position: absolute;
  right: 0;
  border: black solid 1px;
  background-color: lightgreen;
  box-shadow: inset 0px 0px 3px 0px;
  padding: 1em;
  opacity: 0.8;
  z-index: 4;
}
